import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import UsersListItem from "./components/UsersListItem";

function UsersPage()
{
    const [users, setUsers] = useState([])
    const [usersLoaded, setUsersLoaded] = useState(false)

    const fetchUsersList = () => {
        var fUrl = process.env.REACT_APP_API_END_POINT + 'adminusers/';
            
        fetch(fUrl,
            {
            headers: {
                'Accept': 'application/json',
            },
            method: "GET",
            credentials: "include"
            })
            .then((resp) => resp.json())
            .then((data) => {
                if (data['success']) {
                    setUsers(data['users_list']);
                    setUsersLoaded(true);
                } else {
                    setUsers([]);
                    setUsersLoaded(false);
                }
            }
        );
    }

    useEffect(() => {
        fetchUsersList()
    }, [])

    const getUserListItem = (user) => {
        return (
            <UsersListItem user={ user } key={ user.username } />
        )
    }

    return (
        <div className="container">
            {!usersLoaded && <div className="my-5" style={{ textAlign: "center" }}><FontAwesomeIcon className="spinner" icon={solid("spinner")} style={{color: "#000000", height: "50px", width: "50px" }} /></div> }
            {usersLoaded && <div style={{display: "grid", gridTemplateColumns: "300px 200px 150px 100px 170px 120px 120px 162px 170px 80px"}} >
                <div className="dataGridCell dataGridCellHeader" style={{textAlign: "left"}}><strong>Email / Username</strong></div>
                <div className="dataGridCell dataGridCellHeader" style={{textAlign: "left"}}><strong>Name</strong></div>
                <div className="dataGridCell dataGridCellHeader" style={{textAlign: "left"}}><strong>Phone</strong></div>
                <div className="dataGridCell dataGridCellHeader" style={{textAlign: "left"}}><strong>Signup</strong></div>
                <div className="dataGridCell dataGridCellHeader" style={{textAlign: "left"}}><strong>Last login</strong></div>
                <div className="dataGridCell dataGridCellHeader" style={{textAlign: "left"}}><strong>Invite code</strong></div>
                <div className="dataGridCell dataGridCellHeader" style={{textAlign: "left"}}><strong>Scheme</strong></div>
                <div className="dataGridCell dataGridCellHeader" style={{textAlign: "left"}}><strong>Prelim/Prem count</strong></div>
                <div className="dataGridCell dataGridCellHeader" style={{textAlign: "left"}}><strong>Last report</strong></div>
                <div className="dataGridCell dataGridCellHeader" style={{textAlign: "left"}}><strong>Credits</strong></div>
                { users.map((user) => getUserListItem(user))}
            </div>}
        </div>
    )

}

export default UsersPage;